import { Button, Input, Popconfirm, Space, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import { EditOutlined } from "@ant-design/icons";

// import Highlighter from 'react-highlight-words';
const ComboTable = ({
  dataSource,
  columns: firstColumns,
  loading,
  isLoadingDelete,
}) => {
  const [columns, setColumns] = useState(firstColumns);
  const searchButtonRef = useRef(null);
  const resetButtonRef = useRef(null);
  const searchInput = useRef(null);

  const actionColumns = [
    {
      title: "",
      dataIndex: "actions",
      width: 150,
      render: (_, record) => {
        return (
          <ActionContainer>
            {/* <Button
              type="primary"
              style={{ backgroundColor: "rgb(103, 194, 58)" }}
              onClick={() => {
                record?.action(record, "show");
              }}
              icon={<EditOutlined />}
            >
              Үзэх
            </Button> */}
            <Button
              type="primary"
              onClick={() => {
                record?.action(record, "edit");
              }}
              icon={<EditOutlined />}
            >
              Засах
            </Button>
            <Popconfirm
              title="Устгах"
              description="Устгахдаа итгэлтэй байна уу?"
              onConfirm={(ev) => record?.action(record, "delete")}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button
                danger
                type="primary"
                icon={<DeleteOutlined />}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                loading={isLoadingDelete}
                position="end"
              >
                Устгах
              </Button>
            </Popconfirm>
          </ActionContainer>
        );
      },
    },
  ];
  useEffect(() => {
    resetButtonRef.current?.click();
    searchButtonRef.current?.click();
  }, [dataSource]);
  useEffect(() => {
    columnAddWithFilters([...firstColumns]);
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            ref={searchButtonRef}
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            ref={resetButtonRef}
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          {/* <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    // render: (text) =>
    //   text,
    // // ),
  });
  const columnAddWithFilters = (cols) => {
    const columnWithFilters = cols.map((column) => {
      return { ...column, ...getColumnSearchProps(column.dataIndex) };
    });
    setColumns([...columnWithFilters, ...actionColumns]);
  };
  return (
    <Container>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        loading={loading}
      />
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
`;
const ActionContainer = styled.div`
  display: flex;
  gap: 20px;
`;
export default ComboTable;
