import { Form, Input, Select } from "antd";
import React, { useEffect } from "react";
import styled from "styled-components";
import ItemTable from "./ItemTable";

const RawItem = ({ name, value = {}, onChange, validateOptionName }) => {
  useEffect(() => {
    const items = value?.items?.filter((item) => item.type === value.type);
    onChange?.({ ...value, items: items });
  }, [value.type]);
  return (
    <Container>
      <div className="head-item">
        <div className="top-option">
          <Form.Item
            label="Нэр"
            name={[name, "optionName"]}
            rules={[
              {
                required: true,
                message: "Нэр оруулна уу!",
              },
              {
                required: true,
                message: "Давтагдсан нэр байна!",
                validator: validateOptionName,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Type" name={[name, "type"]}>
            <Select
              style={{
                width: 110,
              }}
              options={[
                { value: "pizza", label: "pizza" },
                { value: "single", label: "single" },
              ]}
            />
          </Form.Item>
        </div>
        <Form.List name={[name, "items"]}>
          {() => (
            <ItemTable
              dataSource={value?.items ?? []}
              changeData={(data) => {
                onChange?.({ ...value, items: data });
              }}
            />
          )}
        </Form.List>
      </div>
    </Container>
  );
};

export default RawItem;
const Container = styled.div`
  width: 100%;
  .head-item {
    .top-option {
      display: flex;
      gap: 20px;
    }
    .bottom-option {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
    }
  }
`;
