import { Button, Popconfirm, Table } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { tableMenus as firstColumns } from "utils/tableModels";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import DragableTable, { DragHandle, Row } from "./DragableTable";

const ItemTable = ({ dataSource: firstDataSource, disabled, changeData }) => {
  const [dataSource, setDataSource] = useState([]);
  // const [columns, setColumns] = useState(firstColumns);
  useEffect(() => {
    const mappedData = firstDataSource?.map((item, index) => {
      return { ...item, key: index + 1 };
    });
    setDataSource(mappedData);
    // if (!disabled) {
    //   setColumns();
    // }
  }, [firstDataSource]);
  const confirm = (index) => {
    changeData(dataSource.filter((item, ind) => ind !== index));
    setDataSource(dataSource.filter((item, ind) => ind !== index));
  };

  return (
    <Container>
      <DragableTable
        setDataSource={setDataSource}
        setFormData={changeData}
        dataSource={dataSource}
      >
        <Table
          rowKey="key"
          components={{
            body: {
              row: Row,
            },
          }}
          pagination={false}
          width={800}
          dataSource={dataSource.map((item, index) => ({
            ...item,
            delete: () => confirm(index),
          }))}
          columns={columns}
          scroll={{
            x: "max-content",
            // y: 350
          }}
        />
      </DragableTable>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  overflow: scroll;
`;
export default ItemTable;
const columns = [
  {
    key: "sort",
    align: "center",
    width: 80,
    render: () => <DragHandle />,
  },
  ...firstColumns,
  {
    title: "",
    dataIndex: "actions",
    fixed: "right",
    rowScope: "row",

    render: (_, record) => {
      return (
        <Popconfirm
          title="Устгах"
          description="Устгахдаа итгэлтэй байна уу?"
          onConfirm={() => record?.delete()}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
          // style={{ display: disabled ? "none" : "block" }}
        >
          <Button
            size="small"
            danger
            type="primary"
            icon={
              <DeleteOutlined
              // style={{ display: disabled ? "none" : "block" }}
              />
            }
          >
            Устгах
          </Button>
        </Popconfirm>
        // </ButtonContainer>
      );
    },
  },
];
