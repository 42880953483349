import { useState } from "react";
import { NotificationManager } from "react-notifications";
import configFile from "config.json";
import axios from "utils/axios";
import styled from "styled-components";
import { SelectStore } from "components/Form/InputTypes";
import { Button, Form, Modal as AntModal } from "antd";
import MenuTable from "components/Table/ComboTable";
import { tableCombo as columns } from "utils/tableModels";
import { PlusOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import ActionCombo from "components/Modal/Combo/ActionCombo";

const Combo = () => {
  const [form] = Form.useForm();
  const [store, setStore] = useState();
  const [select, setSelect] = useState({
    isOpen: false,
    type: "add",
    data: {},
  });
  const {
    data: comboMenus = [],
    isLoading,
    refetch,
  } = useQuery({
    enabled: !!store,
    queryKey: [`getComboMenus`, store],
    queryFn: () => fetchComboMenus(),
  });
  const fetchComboMenus = async () => {
    try {
      const { data } = await axios.post(
        `${configFile.PROD_SERVER_URL}/menu/getComboMenus`,
        {
          storeName: store,
        }
      );
      data.data.sort(function (a, b) {
        const nameA = a.masterName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.masterName.toUpperCase(); // ignore upper and lowercase
        // sort in an ascending order
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      });
      return data?.data;
      // setTableData(mappedData);
      // }
    } catch (error) {
      throw error?.response;
    }
  };
  const handleAction = (instance, type) => {
    if (type === "delete") {
      mutate(instance);
    } else {
      setSelect({ isOpen: true, data: instance, type });
    }
    // setInstance(instance);
    // setType(actionType);
    // showModal();
  };
  const handleCreate = () => {
    setSelect({ isOpen: true, data: {}, type: "add" });
    // setType("create");
    // showModal();
  };
  const deleteCombo = async (record) => {
    try {
      const response = await axios.post(
        `${configFile.PROD_SERVER_URL}/menu/deleteComboMenu`,
        {
          id: record.id,
        }
      );
      return response.data;
    } catch (error) {
      throw error?.response;
    }
  };

  const { mutate, isPending: isLoadingDelete } = useMutation({
    mutationFn: (data) => deleteCombo(data),
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      NotificationManager.error(error?.data?.errorMessage, "Алдаа");
    },
  });
  return (
    <Container>
      <Form form={form}>
        <TopHead>
          <SelectStore form={form} name="store" onChange={setStore} />
          {store && (
            <Button
              type="primary"
              onClick={handleCreate}
              icon={<PlusOutlined />}
            >
              Шинээр үүсгэх
            </Button>
          )}
        </TopHead>
        {/* {store && ( */}
        <MenuTable
          columns={columns}
          dataSource={comboMenus.map((menu, index) => ({
            ...menu,
            key: index + 1,
            action: handleAction,
          }))}
          isLoadingDelete={isLoadingDelete}
          loading={isLoading}
        />
      </Form>
      <AntModal
        width={"1600px"}
        title={
          select?.type === "add" ? "Шинээр үүсгэх" : select?.data?.masterName
        }
        onCancel={() => setSelect({ isOpen: false })}
        open={select.isOpen}
        footer
        // okType="primary"
      >
        <ActionCombo
          select={select}
          setSelect={setSelect}
          refetch={refetch}
          store={store}
        />
      </AntModal>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const TopHead = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export default Combo;
