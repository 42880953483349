import axios from "utils/axios";
import { AutoComplete, Form, Input, Select, Space } from "antd";
import React, { useEffect } from "react";
import configFile from "config.json";
import { NotificationManager } from "react-notifications";

const SelectHoroo = ({
  name,
  form,
  required,
  district: firstDistrict,
  ...props
}) => {
  const [data, setData] = React.useState([]);
  // const [count, setCount] = React.useState("");
  const [district, setDistrict] = React.useState(firstDistrict);
  const [searchData, setSearchData] = React.useState([]);

  useEffect(() => {
    form.setFieldsValue({
      [name]: props.value,
    });
  }, []);

  useEffect(() => {
    // setCount("");
    district
      ? fetchData()
      : form.setFieldsValue({
          [name]: "",
        });
  }, [district]);

  useEffect(() => {
    setDistrict(form.getFieldsValue(["district"]).district);
  });

  const handleSearch = (value) => {
    value = value.toLowerCase();
    form.setFieldsValue({
      [name]: value,
    });
    const searchedData = data.filter((item) => {
      const val = item.value.toLowerCase();
      if (val.includes(value)) {
        return item;
      }
    });
    setSearchData(searchedData);
  };

  const fetchData = async () => {
    try {
      const { data } = await axios.get(
        `${configFile.SERVER_URL_ADDRESS}/getHoroo?district=${district}`
      );
      let array = [];
      data.data?.map((horoo) => {
        if (props.onlyNumber) {
          const key = horoo.key.split("-р")[0];
          if (+key) {
            array.push({
              value: horoo.key,
              label: +key,
              doc_count: horoo.doc_count,
            });
          }
        } else {
          array.push({
            value: horoo.key,
            label: `${horoo.key}(${horoo.doc_count})`,
            doc_count: horoo.doc_count,
          });
        }
      });
      setData(array);
      setSearchData(array);
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        "Error",
        5000
      );
    }
  };
  // const forwardChange = (value, item) => {
  //   item ? setCount(item.doc_count) : setCount("");
  // };

  return (
    // <Space.Compact style={{ width: "100%" }}>
      <Form.Item
        label="Хороо"
        name={name}
        // style={{ margin: 0 }}
        rules={[{ required: required, message: `${props.label} оруулна уу !` }]}
      >
        {/* <Select
          allowClear
          disabled={district ? false : true}
          style={{ minWidth: "200px" }}
          options={data}
          onChange={forwardChange}
        /> */}
        <AutoComplete
          allowClear
          dropdownMatchSelectWidth={252}
          style={{
            width: 300,
          }}
          options={searchData}
          onSearch={handleSearch}
        >
          <Input placeholder="Хороо сонгоно уу" />
        </AutoComplete>
      </Form.Item>
      // {props.onlyNumber && (
      //   <Form.Item style={{ margin: 0, width: "100%" }}>
      //     <Input value={`хороо${count ? `(${count})` : ""}`} disabled />
      //   </Form.Item>
      // )}
    // </Space.Compact>
  );
};
// const Container = styled.div``;
export default SelectHoroo;
