import { Form, Modal as AntModal, Select, Input } from "antd";
// import { SelectTypeCombo } from "components/Form/InputTypes";
import styled from "styled-components";
const Modal = ({
  saveButtonText,
  cancelButtonText,
  isShowModal,
  setIsShowModal,
  handleSave,
  title,
  rawSideItems,
}) => {
  const [form] = Form.useForm();
  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 12,
    },
  };
  const handleValidate = async () => {
    try {
      let values = await form.validateFields();
      handleSave(values);
    } catch (error) {
      console.log(error);
    }
  };
  const handleCancel = async () => {
    setIsShowModal(false);
  };

  const validateOptionName = (rule, newValue) => {
    const foundItem = rawSideItems?.filter((item) => {
      if (item.optionName === newValue) {
        return item;
      }
    });
    if (foundItem?.length > 0) {
      return Promise.reject("Давтагдсан нэр байна");
    }
    return Promise.resolve();
  };

  return (
    <AntModal
      // width={1200}
      onOk={handleValidate}
      onCancel={handleCancel}
      open={isShowModal}
      okText={saveButtonText}
      cancelText={cancelButtonText}
      okType="primary"
    >
      <Container>
        <div className="title">{title}</div>
        <Form
          form={form}
          {...layout}
          initialValues={{ optionName: "", type: "pizza" }}
        >
          {/* <Input
            name={"optionName"}
            form={form}
            label={"Нэр"}
            required={true}
            customValidate={validateOptionName}
            // value={}
          />
          <SelectTypeCombo
            name={"type"}
            form={form}
            label={"Төрөл"}
            required={true}
            value="pizza"
          /> */}
          <Form.Item
            label="Нэр"
            name="optionName"
            rules={[
              {
                required: true,
                message: "Нэр оруулна уу!",
              },
              {
                required: true,
                message: "Давтагдсан нэр байна!",
                validator: validateOptionName,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Төрөл" name="type">
            <Select
              style={{
                width: 110,
              }}
              options={[
                { value: "pizza", label: "pizza" },
                { value: "single", label: "single" },
              ]}
            />
          </Form.Item>
        </Form>
      </Container>
    </AntModal>
  );
};
const Container = styled.div`
  .title {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    display: flex;
  }
`;
export default Modal;
