import {  useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import configFile from "config.json";
import axiosCustom from "utils/axios";
import styled from "styled-components";
import { SelectStore } from "components/Form/InputTypes";
import { Button, Checkbox, Divider, Form, Tag } from "antd";
import DeleteCombos from "./DeleteCombos";
const CheckboxGroup = Checkbox.Group;

const CopyCombo = () => {
  const [comboOptions, setComboOptions] = useState([]);
  const [form] = Form.useForm();
  const [store, setStore] = useState();
  const [stores, setStores] = useState();

  const [checkedFromList, setCheckedFromList] = useState([]);
  const [indeterminateFrom, setIndeterminateFrom] = useState(true);
  const [checkAllFrom, setCheckAllFrom] = useState(false);
  const [checkedToList, setCheckedToList] = useState([]);
  const [indeterminateTo, setIndeterminateTo] = useState(true);
  const [checkAllTo, setCheckAllTo] = useState(false);

  useEffect(() => {
    setCheckedFromList([]);
    setCheckedToList([]);
    if (store) {
      fetchDataGetCombos();
    }
  }, [store]);

  const fetchDataGetCombos = async () => {
    try {
      const {
        data: { data },
      } = await axiosCustom.post(
        `${configFile.PROD_SERVER_URL}/menu/getComboMenus`,
        {
          storeName: store,
        }
      );
      const options = data.map((item, index) => ({
        label: (
          <div>
            {item.masterName}
            <Tag color={item.active ? "green" : "volcano"} key={index}>
              {item.active ? "Харагдана" : "Харагдахгүй"}
            </Tag>
          </div>
        ),
        value: item.id,
      }));
      setComboOptions(options);
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        "Error",
        5000
      );
    }
  };
  const handleStore = (item) => {
    setStore(item);
  };
  const onCheckAllChangeFrom = (e) => {
    setCheckedFromList(
      e.target.checked ? comboOptions.map((item) => item.value) : []
    );
    setIndeterminateFrom(false);
    setCheckAllFrom(e.target.checked);
  };
  const onCheckAllChangeTo = (e) => {
    setCheckedToList(e.target.checked ? stores : []);
    setIndeterminateTo(false);
    setCheckAllTo(e.target.checked);
  };
  const onChangeFrom = (list) => {
    setCheckedFromList(list);
    setIndeterminateFrom(!!list.length && list.length < comboOptions.length);
    setCheckAllFrom(list.length === comboOptions.length);
  };

  const onChangeTo = (list) => {
    setCheckedToList(list);
    setIndeterminateTo(!!list.length && list.length < stores.length);
    setCheckAllTo(list.length === stores.length);
  };

  const handleCopy = async () => {
    // const fromIds = checkedFromList.map(
    //   (item) => combos?.filter((combo) => combo.masterName === item)[0].id
    // );
    const to = checkedToList.map((item) => ({ storeName: item }));
    const from = {
      storeName: store,
      ids: checkedFromList,
    };

    try {
      await axiosCustom.post(
        `${configFile.PROD_SERVER_URL}/menu/copyComboMenus`,
        {
          from,
          to,
        }
      );
      NotificationManager.success("Амжилттай хууллаа");
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        "Error",
        5000
      );
      console.log(error);
    }

    console.log(from, to);
  };

  return (
    <StyledC>
      <Form form={form}>
        <h3 style={{ marginBottom: "24px" }}>Багц хуулах</h3>
        <Container>
          <TopHead>
            <SelectStore
              form={form}
              name="store"
              setStores={setStores}
              onChange={handleStore}
            />
          </TopHead>
          <Content>
            <div className="from">
              <Checkbox
                indeterminate={indeterminateFrom}
                onChange={onCheckAllChangeFrom}
                checked={checkAllFrom}
              >
                Check all
              </Checkbox>
              <Divider />
              <StyledCheckboxGroup
                options={comboOptions}
                value={checkedFromList}
                onChange={onChangeFrom}
              />
            </div>
            <div className="">
              <Button
                onClick={handleCopy}
                disabled={
                  checkedFromList.length === 0 || checkedToList.length === 0
                }
              >
                Хуулах
              </Button>
            </div>
            <div className="to">
              <Checkbox
                indeterminate={indeterminateTo}
                onChange={onCheckAllChangeTo}
                checked={checkAllTo}
              >
                Check all
              </Checkbox>
              <Divider />
              <StyledCheckboxGroup
                options={stores?.filter((item) => item !== store)}
                value={checkedToList}
                onChange={onChangeTo}
              />
            </div>
          </Content>
        </Container>
      </Form>
      <DeleteCombos />
    </StyledC>
  );
};
const Content = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
`;
const Container = styled.div`
  width: 100%;
  background: #fff;
  // height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const TopHead = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const StyledCheckboxGroup = styled(CheckboxGroup)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  label {
    margin: 0 !important;
  }
`;
const StyledC = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: calc(50% - 12px) calc(50% - 12px);
`;
export default CopyCombo;
