import axios from "utils/axios";
// import SelectHoc from 'contexts/SelectHoc';
import { Form, Select } from "antd";
import React from "react";
import configFile from "config.json";
import { NotificationManager } from "react-notifications";

const SelectStore = ({
  name,
  form,
  required,
  onChange,
  setStores,
  ...props
}) => {
  const [data, setData] = React.useState([]);
  const [searchData, setSearchData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    fetchData();
    form.setFieldsValue({
      [name]: props.value,
    });
  }, []);

  const handleOnChange = () => {
    onChange && onChange(form.getFieldsValue("store").store);
  };
  const handleSearch = (value) => {
    value = value.toLowerCase();
    // form.setFieldsValue({
    //   [name]: value,
    // });
    const searchedData = data.filter((item) => {
      const val = item.label.toLowerCase();
      if (val.includes(value)) {
        return item;
      }
    });
    setSearchData(searchedData);
  };
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.post(
        `${configFile.PROD_SERVER_URL}/store/list`
      );
      setStores && setStores(data.data.map((item) => item.storeName));
      const mappedResult = data.data.map((d) => ({
        value: d.storeName,
        label: d.storeNameMn ?? d.storeName,
      }));
      setData(mappedResult);
      setSearchData(mappedResult);
      setIsLoading(false);
    } catch (error) {
      NotificationManager.error(
        error.response.data.errorMessage,
        "Error",
        5000
      );
      console.log(error);
      setIsLoading(false);
    }
  };
  return (
    <Form.Item
      {...props.formItemLayout}
      {...props}
      label="Салбар"
      name={name}
      rules={[{ required: required, message: `${props.label} оруулна уу !` }]}
    >
      {/* <Select
        placeholder='Салбар сонгоно уу'
        allowClear
        options={data}
        onChange={handleOnChange}
      /> */}
      {/* <AutoComplete
        dropdownMatchSelectWidth={252}
        style={{
          width: 300,
        }}
        options={searchData}
        onSearch={handleSearch}
        onChange={handleOnChange}
      >
        <Input allowClear placeholder='Салбар сонгоно уу' />
      </AutoComplete> */}
      <Select
        showSearch
        placeholder="Салбар сонгоно уу"
        optionFilterProp="label"
        onChange={handleOnChange}
        onSearch={handleSearch}
        style={{
          width: 300,
        }}
        options={searchData}
        loading={isLoading}
      />
    </Form.Item>
  );
};

export default SelectStore;
