import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Collapse,
  Form,
  Input,
  List,
  Popconfirm,
  Select,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "utils/axios";
import configFile from "config.json";
import TextArea from "antd/es/input/TextArea";
import RawItem from "components/Combo/RawItem";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";
import { CreateCombo } from ".";
import { NotificationManager } from "react-notifications";
const initialValue = {
  active: true,
  masterName: "",
  rawMainItems: {
    optionName: "p1",
    type: "pizza",
    items: [],
  },
  rawSideItems: [],
};
const ActionCombo = ({ store, select, setSelect, refetch }) => {
  const [form] = Form.useForm();
  const { Panel } = Collapse;
  const [searchCond, setSearchCond] = useState("");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const rawMainItems = Form.useWatch("rawMainItems", form) ?? {};
  const rawSideItems = Form.useWatch("rawSideItems", form) ?? [];
  const onFinish = (values) => {
    let mainItems = values.rawMainItems;
    mainItems.items = mainItems.items.map((item) => {
      delete item.key;
      return item;
    });
    let sideItems = values.rawSideItems.map((item) => {
      return {
        ...item,
        items: item?.items?.map((it) => {
          delete it.key;
          return it;
        }),
      };
    });
    const data = {
      storeName: store,
      comboMenuId: values.comboMenuId,
      longDescriptor_stringText: values.longDescriptor_stringText,
      primaryComboGroupId: values.primaryComboGroupId,
      masterName: values.masterName,
      rawMainItems: mainItems,
      rawSideItems: sideItems,
      active: values.active,
    };
    if (select?.type === "add") {
      mutate({ url: "menu/createOrUpdateComboMenu", body: data });
    } else if (select?.type === "edit") {
      mutate({
        url: "menu/createOrUpdateComboMenu",
        body: {
          ...data,
          id: select?.data?.id ?? null,
        },
      });
    }
  };

  useEffect(() => {
    if (select?.type === "add") {
      form.setFieldsValue(initialValue);
    } else {
      form.setFieldsValue({ ...select?.data });
    }
    return () => {
      form.resetFields();
    };
  }, [select?.data]);

  const {
    data: menus = {},
    isLoading,
    // refetch,
  } = useQuery({
    enabled: !!store,
    queryKey: [`getMenus`, select, select?.data?.id],
    queryFn: () => fetchMenus(),
  });

  const fetchMenus = async () => {
    try {
      const { data } = await axios.post(
        `${configFile.PROD_SERVER_URL}/menu/getMenus`,
        {
          storeName: store,
        }
      );
      return data?.data;
      // setTableData(mappedData);
      // }
    } catch (error) {
      throw error?.response;
    }
  };
  const changeCombo = (value, _) => {
    form.setFieldsValue({
      masterName: _.label,
      longDescriptor_stringText: _.longDescriptor_stringText,
    });
  };

  const condimentData = () => {
    const value = searchCond.toLowerCase();
    return menus?.Condiments?.filter((item) => {
      const masterName = item.masterName.toLowerCase();
      const miMasterObjNum = item.miMasterObjNum.toLowerCase();
      if (masterName.includes(value) || miMasterObjNum.includes(value)) {
        return item;
      }
    });
  };
  const validateOptionName = (rule, newValue) => {
    const foundItem = rawSideItems?.filter((item) => {
      if (item.optionName === newValue) {
        return item;
      }
    });
    if (foundItem?.length > 1) {
      return Promise.reject("Давтагдсан нэр байна");
    }
    return Promise.resolve();
  };

  const requestFunc = async (data) => {
    try {
      const response = await axios.post(
        `${configFile.PROD_SERVER_URL}/${data.url}`,
        data?.body
      );
      return response.data;
    } catch (error) {
      throw error?.response;
    }
  };

  const { mutate, isPending: isLoadingUpdate } = useMutation({
    mutationFn: (data) => requestFunc(data),
    onSuccess: () => {
      NotificationManager.success("Амжилттай");
      refetch();
      setSelect({ isOpen: false });
    },
    onError: (error) => {
      NotificationManager.error(error?.data?.errorMessage, "Алдаа");
    },
  });
  return (
    <>
      <StyledForm
        form={form}
        // initialValues={select?.data}
        onFinish={onFinish}
        onFinishFailed={(error) => {
          console.log("failed", error);
        }}
      >
        <div className="head-container">
          <Form.Item
            label="Нэр"
            name="masterName"
            rules={[
              {
                required: true,
                message: "Нэр оруулна уу!",
              },
            ]}
          >
            <Input readOnly={true} />
          </Form.Item>
          <Form.Item
            label="primaryComboGroupId"
            name="primaryComboGroupId"
            rules={[
              {
                required: true,
                //   message: "primaryComboGroupId оруулна уу!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="comboMenuId"
            label="Багц"
            rules={[{ required: true, message: `Багц оруулна уу !` }]}
          >
            <Select
              loading={isLoading}
              style={{
                width: "200px",
              }}
              onChange={changeCombo}
              options={
                menus?.Combo?.filter(
                  (obj, index, self) =>
                    index ===
                    self.findIndex(
                      (t) => t.miMasterObjNum === obj.miMasterObjNum
                    )
                )?.map((item) => ({
                  value: item.miMasterObjNum,
                  label: item.masterName,
                  longDescriptor_stringText: item.longDescriptor_stringText,
                })) ?? []
              }
              // onChange={changeData}
            />
          </Form.Item>
          <Form.Item
            label="Төлөв"
            name="active"
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: "Нэр оруулна уу!",
              },
            ]}
          >
            <Switch />
          </Form.Item>
        </div>
        <Form.Item label="Тайлбар" name="longDescriptor_stringText">
          <TextArea />
        </Form.Item>
        <div className="comboBody">
          <div className="column">
            <Input.Search
              size="small"
              placeholder="Бүтээгдэхүүн хайх"
              value={searchCond}
              onChange={(val) => setSearchCond(val.target.value)}
              // value={searchCond}
              // onChange={searchCondiments}
              enterButton
            />
            <ListStyle
              loading={isLoading}
              dataSource={condimentData()}
              renderItem={(item, index) => (
                <List.Item key={item.miMasterObjNum + index}>
                  <List.Item.Meta
                    title={`${item.masterName} (${item.miMasterObjNum})`}
                  />
                  <div className="rawitems">
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        form.setFieldValue(
                          ["rawMainItems", "items"],
                          [...rawMainItems?.items, item]
                        );
                      }}
                      disabled={
                        rawMainItems?.items?.filter(
                          (i) =>
                            i.masterName === item.masterName &&
                            i.miMasterObjNum === item.miMasterObjNum
                        ).length > 0 || rawMainItems?.type !== item.type
                      }
                    >
                      {rawMainItems?.optionName}
                    </Button>
                    {rawSideItems?.map((ite, index) => {
                      return (
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => {
                            form.setFieldValue(
                              ["rawSideItems", index, "items"],
                              [...ite?.items, item]
                            );
                          }}
                          disabled={
                            ite?.items?.filter(
                              (i) =>
                                i.masterName === item.masterName &&
                                i.miMasterObjNum === item.miMasterObjNum
                            ).length > 0 || ite?.type !== item?.type
                          }
                        >
                          {ite?.optionName}
                        </Button>
                      );
                    })}
                  </div>
                  {/* <div>Content</div> */}
                </List.Item>
              )}
            />
          </div>
          <div className="column">
            <Collapse defaultActiveKey={"main"} expandIconPosition="end">
              <Panel header="Main items" key="main">
                <Form.Item
                  // label="Тайлбар"
                  name="rawMainItems"
                >
                  <RawItem name="rawMainItems" />
                </Form.Item>
              </Panel>
            </Collapse>
            <Collapse expandIconPosition="end" defaultActiveKey={"side"}>
              <Panel
                header="Side items"
                key="side"
                extra={
                  <Button
                    size="small"
                    style={{ backgroundColor: "rgb(103, 194, 58)" }}
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={(event) => {
                      event.stopPropagation();
                      setIsCreateModalOpen(true);
                    }}
                  >
                    Нэмэх
                  </Button>
                }
              >
                <Form.List name="rawSideItems">
                  {(fields, { move }) => (
                    <Collapse
                      // size="small"
                      defaultActiveKey={"0"}
                      expandIconPosition="end"
                    >
                      {fields.map((field, index) => (
                        <Panel
                          header={
                            form.getFieldValue("rawSideItems")[field.name]
                              ?.optionName
                          }
                          key={field.name}
                          extra={
                            <div className="extraItems">
                              <Popconfirm
                                title="Устгах"
                                description="Устгахдаа итгэлтэй байна уу?"
                                onConfirm={(ev) =>
                                  form.setFieldValue(
                                    "rawSideItems",
                                    rawSideItems.filter(
                                      (item, ind) => ind !== index
                                    )
                                  )
                                }
                                onCancel={() => {}}
                                okText="Yes"
                                cancelText="No"
                              >
                                <Button
                                  size="small"
                                  danger
                                  type="primary"
                                  icon={<DeleteOutlined />}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                >
                                  Устгах
                                </Button>
                              </Popconfirm>
                              <div className="arrows">
                                <AiOutlineArrowUp
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    move(field.name, field.name - 1);
                                  }}
                                  className="arrowItemUp arrowItem"
                                  size={20}
                                />
                                <AiOutlineArrowDown
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    move(field.name, field.name + 1);
                                    //   changePosition(index, 1);
                                  }}
                                  className="arrowItemDown arrowItem"
                                  size={20}
                                />
                              </div>
                            </div>
                          }
                        >
                          <Form.Item
                            // label="Тайлбар"
                            name={field.name}
                          >
                            <RawItem
                              name={field.name}
                              validateOptionName={validateOptionName}
                            />
                          </Form.Item>
                        </Panel>
                      ))}
                    </Collapse>
                  )}
                </Form.List>
              </Panel>
            </Collapse>
          </div>
        </div>
        <StyledAction>
          <Button onClick={() => setSelect({ isOpen: false })}>Хаах</Button>
          <Button
            htmlType="submit"
            type="primary"
            loading={isLoadingUpdate}
            position="end"
          >
            Хадгалах
          </Button>
        </StyledAction>
      </StyledForm>

      {isCreateModalOpen && (
        <CreateCombo
          // width={"1200px"}
          isShowModal={isCreateModalOpen}
          cancelButtonText={"Хаах"}
          setIsShowModal={setIsCreateModalOpen}
          saveButtonText="Хадгалах"
          rawSideItems={rawSideItems}
          handleSave={(values) => {
            form.setFieldValue("rawSideItems", [
              ...rawSideItems,
              {
                ...values,
                items: [],
              },
            ]);
            setIsCreateModalOpen(false);
          }}
          okType="primary"
          title="Шинээр үүсгэх"
        />
      )}
    </>
  );
};

export default ActionCombo;
const StyledForm = styled(Form)`
  position: relative;
  .head-container {
    display: flex;
    width: 100%;
    column-gap: 20px;
    flex-wrap: wrap;
  }
  .comboBody {
    width: 100%;
    display: flex;
    .column {
      padding: 10px;
      height: 500px;
      overflow: auto;
      flex: 1;
      .extraItems {
        display: flex;
        gap: 20px;
        .arrows {
          display: flex;
          gap: 10px;
          .arrowItem {
            cursor: pointer;
          }
        }
      }
    }
  }
`;
const StyledAction = styled.div`
  display: flex;
  justify-content: end;
  gap: 20px;
`;
const ListStyle = styled(List)`
  border: 1px solid rgba(140, 140, 140, 0.35);
  padding: 0 16px;
  margin-top: 10px;
  border-radius: 5px;
  .rawitems {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    flex: 1;
    justify-content: end;
  }
`;
